import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ENVIRONMENT_VARIABLES } from '@/environmentVariables';

const getNoSentryError = (message: string) => {
  return new Error(`NO_SENTRY: ${message}`);
};

const init = () => {
  if (ENVIRONMENT_VARIABLES.DEPLOYMENT_MODE !== 'remote') {
    return;
  }

  Sentry.init({
    environment: ENVIRONMENT_VARIABLES.ENV,
    dsn: 'https://cf4d5aae411e44e791856be85eefd61d@o166886.ingest.sentry.io/4505426019942400',
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        enableInp: true,
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    beforeSend(event, hint) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = hint.originalException as any;

      // Exclude errors with messages starting with "NO_SENTRY"
      if (error?.message?.startsWith('NO_SENTRY')) {
        return null;
      }
      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.2,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: `${ENVIRONMENT_VARIABLES.APP_VERSION}`,
  });
};

export const sentryService = { init, getNoSentryError };
