//destructure first and re-export later (JEST fix)
const {
  VITE_APP_ENV,
  VITE_API_URL,
  VITE_KEYCLOAK_REALM,
  VITE_KEYCLOAK_URL,
  VITE_KEYCLOAK_CLIENT_ID,
  VITE_KEYCLOAK_RESOURCE,
  VITE_FIREBASE_API_KEY,
  VITE_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_STORAGE_BUCKET,
  VITE_FIREBASE_MESSAGING_SENDER_ID,
  VITE_FIREBASE_APP_ID,
  VITE_FIREBASE_VAPID_KEY,
  VITE_SENTRY_AUTH_TOKEN,
  VITE_SUPPORT_EMAIL,
  VITE_SUPPORT_PHONE,
} = import.meta.env;

export const ENVIRONMENT_VARIABLES = {
  ENV: VITE_APP_ENV || 'dev',
  API_URL: VITE_API_URL ? `${VITE_API_URL}/api` : 'http://localhost:8080/api',
  KEYCLOAK_REALM: VITE_KEYCLOAK_REALM || 'asklepius_realm',
  KEYCLOAK_URL: VITE_KEYCLOAK_URL || 'http://localhost:8000/',
  KEYCLOAK_CLIENT_ID: VITE_KEYCLOAK_CLIENT_ID || 'vendor-ui',
  KEYCLOAK_RESOURCE: VITE_KEYCLOAK_RESOURCE || 'vendors-service',
  APP_VERSION: process.env.APP_VERSION ?? 'N/A',
  DEPLOYMENT_MODE: process.env.DEPLOYMENT_MODE?.trim() ?? 'N/A',
  VITE_FIREBASE_API_KEY: VITE_FIREBASE_API_KEY || 'N/A',
  VITE_FIREBASE_AUTH_DOMAIN: VITE_FIREBASE_AUTH_DOMAIN || 'N/A',
  VITE_FIREBASE_PROJECT_ID: VITE_FIREBASE_PROJECT_ID || 'N/A',
  VITE_FIREBASE_STORAGE_BUCKET: VITE_FIREBASE_STORAGE_BUCKET || 'N/A',
  VITE_FIREBASE_MESSAGING_SENDER_ID: VITE_FIREBASE_MESSAGING_SENDER_ID || 'N/A',
  VITE_FIREBASE_APP_ID: VITE_FIREBASE_APP_ID || 'N/A',
  VITE_FIREBASE_VAPID_KEY: VITE_FIREBASE_VAPID_KEY || '',
  VITE_SUPPORT_EMAIL: VITE_SUPPORT_EMAIL || 'N/A',
  VITE_SENTRY_AUTH_TOKEN: VITE_SENTRY_AUTH_TOKEN || '',
  VITE_SUPPORT_PHONE: VITE_SUPPORT_PHONE || 'N/A',
};
